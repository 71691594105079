import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useHttp } from "../../hooks/http.hook";
import TableWrap from "../components/Table/TabelWrap";
import SimpleModal from '../components/SimpleModal/SimpleModal'
import tableCss from '../components/Table/style.module.scss'
import { flatten } from 'flat'
import { Stack, Box, Grid, Modal, NativeSelect, InputBase, Paper, TablePagination, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles';
import useAuth from "../../hooks/useAuth.hook";
import Preloader from '../components/Preloader'
import FontSizeSlider from "../components/FontSlider/FontSlider";
import NumberDetails from "../components/NumberDetails/NumberDetails";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';

import s from './s.module.scss'
import UrlTooltip from "../components/UrlTooltip";
import LeadRow from "./LeadRow";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const detailsHandler = (lead) => {
    const partners = {
        'axela': 'message-warning',
        'cpabandtracker': 'message-info',
        'aivix': 'message-danger',
        'cryp.im': 'message-attantion',
        'trump': 'message-dark'

    }
    return partners[lead] || ''
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Leads = () => {
    let query = useQuery();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [fontSize, setFontSize] = React.useState(parseInt(localStorage.getItem('tableFontSize')) || 14);
    const [leads, setLeads] = React.useState([])
    const [sortedUser, setSortedUser] = React.useState('')
    const [countryFilter, setCountryFilter] = React.useState('')
    const [partnerFilter, setPartnerFilter] = React.useState('')
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [openOptions, setOpenOptions] = React.useState(false);
    const [partners, setPartners] = React.useState([])
    const auth = useAuth()
    const { request, loading } = useHttp()
    const [modal, setModal] = React.useState({
        modal: false,
        confirm: false
    })
    const [users, setUsers] = React.useState([])
    const [countries, setCountries] = React.useState([])
    const [details, setDetails] = React.useState(null)
    const [filteredLeads, setFilteredLeads] = React.useState([]);
    const [filteredLeadsCount, setFilteredLeadsCount] = React.useState(0);
    const [postbackPrice, setPostbackPrice] = React.useState(null);
    // Resend
    const [from, setFrom] = React.useState('00:00')
    const [to, setTo] = React.useState('23:59')
    const [changePartner, setChangePartner] = React.useState()
    const [postbackType, setPostbackType] = React.useState('')

    const getLeads = async (e) => {
        const res = await request(`/api/main/get/leads`)
        const resLead = res.leads.map(lead => {
            lead.serachStr = `${lead.id} ${lead.first_name} ${lead.last_name} ${lead.email} ${lead.phone} ${lead?.Link?.offer_name ?? ''}`
            return lead
        })

        setLeads(resLead)
    }

    const startTableRef = React.useRef(null);

    const handleOpenResend = () => {
        // getCheckedLinks()
        setOpenOptions(true);
    }

    const handleCloseResend = () => {
        setOpenOptions(false);
    }

    const getConversionList = async () => {
        const data = await request(`/api/side/conversion/get`, 'POST')
        getLeads()
    }

    const getUsersList = async () => {
        const res = await request(`/api/main/users`)
        setUsers(res.users)
    }

    const getCountries = async () => {
        const res = await request(`/api/main/get/country`)
        setCountries(res.country)
    }

    const sortHandle = (event) => {
        setSortedUser(event.target.value)
        handleChangePage(null, 0)
    }

    const handleScrollToTableStart = () => {
        startTableRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const countryFilterHandler = (event) => {
        setCountryFilter(event.target.value)
    }

    const partnerFilterHandler = (event) => {
        setPartnerFilter(event.target.value)
    }

    React.useEffect(() => {
        getLeads()
        getUsersList()
        getCountries()
    }, [])

    const headers = {
        '': '',
        'id': '',
        ['time']: '',
        ['first name']: '',
        ['last name']: '',
        email: '',
        phone: '',
        ['callcenter status']: '',
        ref: '',
        status: '',
        ip: '',
        ['link name']: '',
        gone: '',
        ['details']: '',
        '': '',
    }

    const [nameFilter, setNameFilter] = React.useState(query.get("q") ? query.get("q") + ' ' : '')

    const nameFilterHandler = (event) => {
        setNameFilter(event.target.value)
    }

    const getPartners = async (e) => {
        const res = await request(`/api/main/get-partners`, 'GET', null, {
            ['x-access-token']: auth.token
        })
        setPartners(res.partners)
        setChangePartner(res.partners[0].endpoint_name)
    }

    const handleDelete = async (id) => {
        try {
            await request(`/api/main/remove/lead/${id}`, 'POSt', {}, { Authorization: `Bearer ${auth.token}` })
            setLeads(leads.filter(lead => lead.id !== id));
        } catch (error) {
            console.error(error);
        }
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        handleScrollToTableStart()
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    React.useEffect(() => {
        localStorage.setItem('tableFontSize', fontSize);
    }, [fontSize]);


    const handleCheckboxChange = React.useCallback((e, id) => {
        e.preventDefault();
        if (e.target.checked) {
            setSelectedIds([...selectedIds, ...leads.filter(el => el.id == id)]);
        } else {
            setSelectedIds(selectedIds.filter((selectedId) => selectedId.id !== id));
        }
    }, [selectedIds, setSelectedIds, leads]);



    const resendLeads = async () => {
        try {
            await request(`/api/main/resend/leads`, 'POST', { ids: selectedIds }, { Authorization: `Bearer ${auth.token}` })
            setSelectedIds([]);
            getLeads()
        } catch (error) {
            console.error(error);
        } finally {
            setOpenOptions(false)
        }
    }

    const changeTime = (e) => {
        const value = e.target.value
        const name = e.target.name

        if (name === 'from') {
            setFrom(value)
        }

        if (name === 'to') {
            setTo(value)
        }
    }

    const addResendOptions = () => {
        setSelectedIds(prev => {

            const newEl = prev.map(el => {

                el.send_time_from = from
                el.send_time_to = to
                el.action = changePartner
                return el
            })

            return newEl

        })
    }


    const resendDefault = () => {
        setSelectedIds(prev => {
            const newEl = prev.map(el => {
                el.send_time_from = '00:00'
                el.send_time_to = '23:59'
                el.action = 'axela'

                return el
            })

            return newEl

        })
    }

    const sendPostback = async () => {
        try {
            const numericPostbackPrice = postbackPrice !== null ? postbackPrice : 0;
            const res = await request(`/api/main/postback`, 'POST', {
                ids: selectedIds,
                type: postbackType,
                price: numericPostbackPrice
            },
                { Authorization: `Bearer ${auth.token}` });

            console.log(res)
            // update leads by res.leads
            res.leads.map(lead => {
                const index = leads.findIndex(el => el.id === lead.id);
                leads[index].postback_status = lead.postback_status;
                setLeads([...leads]);
            });
            alert('Postback sent')
        }
        catch (error) {
            alert('Error')
            console.error(error);
        }
        finally {
            setModal({ ...modal, confirm: false });
        }
    }

    const setQuality = async (id) => {
        try {
            const res = await request(`/api/main/leads/set/quality/${id}`, 'GET', null, { Authorization: `Bearer ${auth.token}` });
            // update Leads quality
            const index = leads.findIndex(el => el.id === id);
            leads[index].quality = !leads[index].quality;
            setLeads([...leads]);
        } catch (error) {
            console.error(error);
        }
    }

    React.useEffect(() => {
        getPartners()
    }, [])

    // React.useEffect(() => {
    //     filterLeads();
    // }, [leads, sortedUser, nameFilter, countryFilter, partnerFilter, selectedIds]);

    const filterLeads = (leads, searchTerm, sortedUser, countryFilter, partnerFilter) => {
        return leads.filter(lead => {
            // Поиск по частичному совпадению serachStr
            const isSearchMatch = searchTerm
                ? lead.serachStr.toLowerCase().includes(searchTerm.toLowerCase())
                : true;

            // Точный фильтр по sortedUser
            const isUserMatch = sortedUser
                ? lead.Link.User.id === sortedUser
                : true;

            // Точный фильтр по countryFilter
            const isCountryMatch = countryFilter
                ? lead.Link.country === countryFilter
                : true;

            // Точный фильтр по partnerFilter
            const isPartnerMatch = partnerFilter
                ? lead.gone.includes(partnerFilter)
                : true;

            // Запись подходит только если все условия выполнены
            return isSearchMatch && isUserMatch && isCountryMatch && isPartnerMatch;
        });
    };

    React.useEffect(() => {
        const filtered = filterLeads(leads, nameFilter, sortedUser, countryFilter, partnerFilter);
        setFilteredLeads(filtered);
        setFilteredLeadsCount(filtered.length);
    }, [leads, nameFilter, sortedUser, countryFilter, partnerFilter, selectedIds]);

    console.log("postbackPrice: ", postbackPrice)
    return <>
        <div className="card" ref={startTableRef}>
            <h2>Leads</h2>
            <Link className='btn btnDanger' to="/">Back</Link>
            <Paper sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center' }}>
                <InputBase
                    type="search"
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search By ID, Name, Last Name or Email"
                    onChange={nameFilterHandler}
                    value={nameFilter}
                />
            </Paper>
            {!!auth.user?.isAdmin && <Box sx={
                {
                    flexGrow: 1,
                    position: 'sticky',
                    top: 0,
                    zIndex: 2,
                    backgroundColor: '#fff',
                    borderBottom: '1px solid #ccc'
                }
            }>
                <Grid container gap={'10px'}>
                    <Item>
                        <button className={loading ? 'btn btnSecondary' : 'btn btnPrimary'} onClick={getConversionList} disabled={loading ? 'disabled' : ''}>Update Status</button>
                    </Item>
                    {!!users.length &&
                        <Item>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                Sort by: <div>
                                    <select onChange={sortHandle} className='btn btnPrimary'>
                                        <option value={''} >All</option>
                                        {users.map(user => <option value={user.id}>{user.email}</option>)}
                                    </select>
                                </div>
                            </div>
                        </Item>
                    }
                    {!!countries.length &&
                        <Item>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                Country: <div>
                                    <select onChange={countryFilterHandler} className='btn btnPrimary'>
                                        <option value={''} >All</option>
                                        {countries.map(country => <option value={country.country}>{country.country}</option>)}
                                    </select>
                                </div>
                            </div>
                        </Item>
                    }

                    {!!auth.user?.isAdmin && !!partners.length &&
                        <Item>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                Partner: <div>
                                    <select onChange={partnerFilterHandler} className='btn btnPrimary'>
                                        <option value={''} >All</option>
                                        {partners.map(partner => <option value={partner.name.toLowerCase()}>{partner.name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </Item>
                    }
                    <Item>
                        <FontSizeSlider fontSize={fontSize} onChange={setFontSize} />
                    </Item>
                    <Item>
                        <button className={`btn ${selectedIds.length != 0 ? 'btnPrimary' : 'btnSecondary'}`} onClick={selectedIds.length != 0 && handleOpenResend}>Resend</button>
                    </Item>
                    <Item sx={{ gap: 2, display: 'flex' }}>
                        <button className={`btn ${selectedIds.length != 0 ? 'btnSuccess' : 'btnSecondary'}`} onClick={
                            () => {
                                if (selectedIds.length <= 0) return;
                                setModal({ ...modal, confirm: true });
                                setPostbackType('success');
                            }
                        }>Success postback</button>
                        <button className={`btn ${selectedIds.length != 0 ? 'btnDanger' : 'btnSecondary'}`} onClick={
                            () => {
                                if (selectedIds.length <= 0) return;
                                setModal({ ...modal, confirm: true });
                                setPostbackType('reject');
                            }
                        }>Reject postback</button>
                    </Item>
                </Grid>
            </Box>}
            {leads.length > 0 ? <>
                {/* selectedIds counts */}

                <Grid container justifyContent={'flex-end'} sx={{ flexWrap: 'nowrap', mt: 2 }}>
                    <div style={{ width: 100 }}><b>Selected Leads:</b> {selectedIds.length}</div>

                    <TablePagination
                        sx={{ width: "100%" }}
                        rowsPerPageOptions={[50, 100, 150, 300, 400, { label: 'All', value: -1 }]}
                        colSpan={3}
                        count={filteredLeadsCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page',
                            },
                            native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
                <TableWrap>
                    <div className={tableCss.table} style={{ fontSize }}>
                        <div className={tableCss.header} style={{ "gridTemplateColumns": `25px 40px ${70 + fontSize}px repeat(9,minmax(80px, 1fr)) ${90 + fontSize}px ${90 + fontSize}px 25px` }}>
                            {Object.keys(headers).map(header => <div>
                                {header}
                            </div>)}
                        </div>
                        <div className={tableCss.content}>
                            {filteredLeads
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((lead, index) => (
                                    <LeadRow
                                        key={lead.id}
                                        lead={lead}
                                        index={index}
                                        page={page}
                                        rowsPerPage={rowsPerPage}
                                        fontSize={fontSize}
                                        handleDelete={handleDelete}
                                        setQuality={setQuality}
                                        setModal={setModal}
                                        modal={modal}
                                        setDetails={setDetails}
                                        selectedIds={selectedIds}
                                        handleCheckboxChange={handleCheckboxChange}
                                        auth={auth}
                                        detailsHandler={detailsHandler}
                                        headers={headers}
                                    />
                                ))}
                        </div>
                    </div>
                </TableWrap>
                <TablePagination
                    sx={{ width: "100%" }}
                    rowsPerPageOptions={[50, 100, 150, 300, 400, { label: 'All', value: -1 }]}
                    colSpan={3}
                    count={filteredLeadsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                        inputProps: {
                            'aria-label': 'rows per page',
                        },
                        native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </> : <Preloader />}
        </div>

        {modal.modal && details && <SimpleModal modalName={'modal'} closeSetter={setModal}>
            <div>Respons Details</div>
            {console.log(details)}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <pre style={{
                    textAlign: 'left',
                    textWrap: 'wrap',
                    background: '#fff'
                }}>
                    <code>
                        {JSON.stringify(details, null, 2)}
                    </code>
                </pre>
            </div>
        </SimpleModal>}

        {/* Confirm postback */}
        {modal.confirm && postbackType != '' && <SimpleModal modalName={'confirm'} closeSetter={setModal}>
            <div style={{
                marginTop: 'auto',
            }}>
                {postbackType == 'success' ? 'Are you sure you want to send success postback?' : 'Are you sure you want to send reject postback?'}
            </div>
            {postbackType == 'success' ? <input
                onChange={(e) => {
                    const value = e.target.value;
                    setPostbackPrice(value === '' ? null : Number(value));
                }}
                value={postbackPrice === null ? '' : postbackPrice}
                type="number"
                placeholder="Price"
                style={{ marginBottom: 26 }} /> : null}
            <div style={{ display: 'flex', gap: '10px' }}>
                <div className='btn btnDanger' onClick={setModal}>Cancel</div>
                <div className='btn btnSuccess' onClick={sendPostback}>Send</div>
            </div>
        </SimpleModal>}
        {/* Resend */}
        {!!auth.user?.isAdmin &&
            <Modal
                keepMounted
                className={s.sheduleModal}
                open={openOptions}
                onClose={handleCloseResend}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >

                <Stack sx={style} spacing={1} >
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseResend}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {!!partners.length && changePartner && <Paper>
                        <div className={s.timeWrapForAll}>
                            From <input type={'time'} name='from' value={from} onChange={changeTime} />
                            to <input onChange={changeTime} name='to' min={from} type={'time'} value={to} />

                            transfer to → <NativeSelect
                                defaultValue={partners.filter(partner => partner.is_active)[0].endpoint_name}
                                inputProps={{
                                    name: 'age',
                                    id: 'uncontrolled-native',
                                }}
                                onChange={e => setChangePartner(e.target.value)}
                            >
                                {partners
                                    .filter(partner => partner.is_active)
                                    .map(partner => <option value={partner.endpoint_name}>{partner.name}</option>)}
                            </NativeSelect>

                            <div className="btn btnSuccess" onClick={addResendOptions}>Set All</div>
                            <div className="btn btnDanger" title="Set Default Value For All" onClick={resendDefault}><DeleteForeverIcon /></div>

                        </div>
                    </Paper>}

                    <div className={s.transferModal}>
                        {selectedIds.map((el) => <HolderItem key={el.id} link={el} partners={partners.filter(partner => partner.is_active)} setSelectedIds={setSelectedIds} />)}
                    </div>
                    <div className={`btn ${loading ? 'btnSecondary' : 'btnSuccess'}`} onClick={loading ? null : resendLeads}>Save</div>

                </Stack>
            </Modal>
        }
    </>
}

const HolderItem = ({ link, partners, setSelectedIds }) => {
    const changeTime = (e) => {
        const value = e.target.value
        const name = e.target.name

        setSelectedIds(prev => {
            const newEl = prev.map(el => {
                if (link.id === el.id) {
                    if (name === 'from')
                        el.send_time_from = value

                    if (name === 'to')
                        el.send_time_to = value

                }
                return el

            })

            return newEl

        })
    }

    const setChangePartner = (e) => {
        const value = e.target.value

        setSelectedIds(prev => {
            const newEl = prev.map(el => {
                if (link.id === el.id) {
                    el.action = value
                }
                return el

            })

            return newEl

        })
    }

    const removeHolder = (linkId, id) => {
        setSelectedIds(prev => {
            const newEl = prev.map(el => {
                el.send_time_from = '00:00'
                el.send_time_to = '23:59'
                return el
            })

            return newEl

        })
    }

    return <Paper>
        <div className={s.transfer_card}>

            <div className={s.header}>
                <div>{link.first_name}</div>
                <div>{link.last_name}</div>
                <div>{link.email}</div>
                <div><NumberDetails phoneNumber={link['phone']} /></div>
                <div>{link.Link.offer_name}</div>
                <div className={`message ${detailsHandler(link.gone || flatten(link?.other_params)?.partner || '')}`} style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={`${link.gone || (flatten(link?.other_params)?.partner ?? '')}`}>{link.gone || (flatten(link?.other_params)?.partner ?? '')}</div>
            </div>
            <div className={s.timeWrap}>
                From <input type={'time'} name='from' value={link.send_time_from || '00:00'} onChange={changeTime} />
                to <input onChange={changeTime} min={link.send_time_from || '00:00'} name='to' type={'time'} value={link.send_time_to || '23:59'} />
                transfer to → <NativeSelect
                    defaultValue={partners
                        .filter(partner => partner.is_active)
                    [0].endpoint_name}
                    value={link.action}
                    inputProps={{
                        name: 'age',
                        id: 'uncontrolled-native',
                    }}
                    onChange={setChangePartner}
                >
                    {partners
                        .filter(partner => partner.is_active)
                        .map(partner => <option value={partner.endpoint_name}>{partner.name}</option>)}
                </NativeSelect>
            </div>

            <div className={s.shedule}>
                {/* <Stack sx={{ width: '100%' }} spacing={1}>
                {link.Holders && link.Holders.map((el, i) => <Alert severity="warning" className="shedule-alert">
                    From <b>{el.holder_from}</b> to <b>{el.holder_to}</b>
                    <div className='close' onClick={() => {
                        removeHolder(link.id, el.id)
                    }}><CloseIcon /></div>
                </Alert>)}
            </Stack> */}
            </div>

        </div>
    </Paper>
}

export default Leads